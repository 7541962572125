/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Box, Flex, FlexItem, Button, Header, Loader } from '@fluentui/react-northstar';

import { useGetcountriesMutation } from 'redux/services/countryApi';
import {
  useOnboardingTenantDialPlanMutation,
  useOnboardingCallingIdentityMutation,
  useGetOnboardingStatusMutation,
  useRetrySetupMutation,
} from 'redux/services/onBoardingApi';

import { useInterval } from 'hooks/useInterval';

import { AppContext } from 'contexts/AppContext';
import { AuthContext } from 'contexts/AuthContext';

import { deepRefresh } from 'helpers/utils';
import { BatchUpdateResult, JobStatus, OnboardingStatus, OnboardingStep, RtkSuccessResponseType } from 'types';
import { ONBOARDING_STEPS_OC, INCREMENT_TIME, TIMEOUT_SECONDS } from '../../../constants';

import ErrorBox from './ErrorBox';
import Step1 from './contents/Step1';
import Step2 from './contents/Step2';
import Step3 from './contents/Step3';

import Stepper from 'components/common/Stepper';
import { useDownloadTenantData } from 'hooks/useDownloadTenantData';

type operatorConnectCount = {
  [key: string]: number;
};

const OperatorConnectSetup = (): React.ReactElement => {
  const history = useHistory();

  const { token } = useContext(AuthContext);
  const { onBoardingProgress } = useContext(AppContext);

  const [getCountries, { data: countries, isLoading: isCountriesLoading }] = useGetcountriesMutation();
  const [onboardingCallingIdentity, { data: callingIdentity, isLoading: isCallingIdentityLoading }] =
    useOnboardingCallingIdentityMutation();
  const [onboardDialPlan, { data: dialPlan, isLoading: isDpLoading }] = useOnboardingTenantDialPlanMutation();
  const [getStatus, { data: statusData }] = useGetOnboardingStatusMutation();
  const [retrySetupDomain, { isLoading: isRetrySetupLoading }] = useRetrySetupMutation();
  const { downloadTenantData, isLoading: isDownloadingTenantData } = useDownloadTenantData();

  const [currentCount, setCurrentCount] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);
  const [progressContent, setProgressContent] = useState('');
  const [progressValue, setProgressValue] = useState(0);
  const [resultCount, setResultCount] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [timerValue, setTimerValue] = useState(0);
  const [withError, setWithError] = useState(false);

  const isOnBoardingLoading = isCallingIdentityLoading || isDpLoading;

  const OC_STEP_COUNT: operatorConnectCount = {
    1: 1,
    2: 3,
    3: 6,
  };

  const isOnStep1 = currentStep === 1;
  const isOnStep2 = currentStep === 2;
  const isOnStep3 = currentStep === 3;

  useEffect(() => {
    if (onBoardingProgress && onBoardingProgress.onboardingStep !== OnboardingStep.NONE) {
      const { id: jobId, status, onboardingStep, error, countryCodes } = onBoardingProgress;

      setSelectedCountry(countryCodes);
      setCurrentStep(1);

      if (!error) {
        if (status === JobStatus.FAILED) {
          setCurrentStep(onboardingStep);
        }

        if (status === JobStatus.IN_PROGRESS) {
          setCurrentStep(onboardingStep);
          getStatus({ jobId, token }).then((result) => {
            const res = result as { data: BatchUpdateResult<OnboardingStatus> };
            if (res.data) {
              setResultCount(res.data.results.length);
            }
          });
        }

        if (status === JobStatus.COMPLETED) {
          if (onboardingStep === 6) {
            setIsCompleted(true);
            setCurrentStep(onboardingStep);
          } else {
            setCurrentStep(2);
          }
        }
      } else {
        setWithError(true);
        setErrorMessage(error);
        setCurrentStep(onboardingStep);
      }
    }
  }, [getStatus, onBoardingProgress, token]);

  useEffect(() => {
    if (isCompleted) return;

    const step = OC_STEP_COUNT[currentStep.toString()];

    switch (step) {
      case 1:
        getCountries({ token });
        break;
      case 3:
        onboardingCallingIdentity({ token });
        break;
      case 6:
        onboardDialPlan({ token, body: selectedCountry });
        break;
      case -1:
        retrySetupDomain({ token }).then(() => {
          setCurrentStep(1);
        });
        break;
      default:
    }
  }, [currentStep, getCountries, isCompleted, onboardingCallingIdentity, onboardDialPlan, token]);

  useEffect(() => {
    if (isOnBoardingLoading) {
      setProgressContent('loading...');
    }
  }, [isOnBoardingLoading]);

  useEffect(() => {
    if (timerValue === TIMEOUT_SECONDS) {
      setTimeLeft(30);
    }
  }, [timerValue]);

  useEffect(() => {
    if (timeLeft === 0) {
      deepRefresh();
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useInterval(() => {
    if (isCompleted) return;

    let jobId;

    const step = OC_STEP_COUNT[currentStep.toString()];

    switch (step) {
      case 1:
        if (!countries) getCountries({ token });
        break;
      case 3:
        jobId = callingIdentity?.id;
        break;
      case 6:
        jobId = dialPlan?.id;
        break;
      default:
    }

    setStepperContent(jobId);
    setTimerInfo();
  }, 3500);

  const isProgressCompleted = progressValue === 100;
  const isInProgress = !withError && !isProgressCompleted;

  const setStepperContent = (jobId: string) => {
    const { id, results, status } = statusData || {};

    if (jobId && isInProgress) {
      const totalCount = results?.length;

      if (totalCount) {
        const { name, onboardingRequestType } = results[currentCount].data;
        const mainContent = `${onboardingRequestType} - ${name}`;

        if (results[currentCount] && jobId === id) {
          let updatedCount = currentCount;

          if (results[currentCount].status === JobStatus.COMPLETED || status === JobStatus.COMPLETED) {
            updatedCount = currentCount + 1;
            setCurrentCount(updatedCount);
            setProgressValue(Math.round((updatedCount / totalCount) * 100));
          }

          setProgressContent(`${mainContent}... (${updatedCount} of ${totalCount} completed)`);

          if (results[currentCount].status === JobStatus.FAILED || status === JobStatus.FAILED) {
            setWithError(true);
            setErrorMessage(results[currentCount].errors?.[0]?.message);
          }
        }
      }

      getStatus({ jobId, token }).then((result) => {
        const data = result as RtkSuccessResponseType<BatchUpdateResult<OnboardingStatus>>;
        if (data) {
          setResultCount(data.data.results.length);
        }
      });
    }

    if (isProgressCompleted) {
      if (currentStep !== 3) resetStepperContent();
      if (currentStep === 3) setIsCompleted(true);
    }
  };

  const setTimerInfo = () => {
    if (isInProgress) {
      const stepProgressValue = Math.round(resultCount > 0 ? ((currentCount + 1) / resultCount) * 100 : 0);
      const increasedProgressValue = progressValue + INCREMENT_TIME;

      let currentProgressValue = stepProgressValue;

      if (increasedProgressValue < stepProgressValue) currentProgressValue = increasedProgressValue;

      if (progressValue === currentProgressValue) setTimerValue(timerValue + INCREMENT_TIME);
      else setTimerValue(0);
    } else {
      if (!isProgressCompleted) setTimerValue(timerValue + INCREMENT_TIME);
    }
  };

  const resetState = () => {
    setProgressValue(0);
    setCurrentCount(0);
  };

  const resetError = () => {
    setErrorMessage('');
    setWithError(false);
  };

  const handleNextClick = () => {
    let step = currentStep;
    step++;

    if (step > 0 && step <= 3) {
      setCurrentStep(2);
    }
  };

  const handleRetryProcess = () => {
    if (!withError) return;

    resetError();
    switch (currentStep) {
      case 1:
        getCountries({ token });
        break;
      case 3:
        onboardingCallingIdentity({ token });
        break;
      case 6:
        onboardDialPlan({ token, body: selectedCountry });
        break;
      case -1:
        retrySetupDomain({ token }).then(() => {
          setCurrentStep(1);
        });
        break;
      default:
    }
    resetState();
  };

  const resetStepperContent = () => {
    const step = currentStep + 1;
    setCurrentStep(step);
    resetState();
  };

  const countriesArray = countries?.map((country: { code: string }) => country.code);

  const errorBoxProps = {
    errorMessage,
    timeLeft,
    withError,
  };

  const stepProps = {
    progressContent,
    progressValue,
    withError,
  };

  const step1Props = {
    countriesArray,
    setSelectedCountry,
  };

  const Step3Props = {
    ...stepProps,
    isCompleted,
  };

  return (
    <>
      {isOnBoardingLoading ? (
        <Loader style={{ marginTop: 70 }} />
      ) : (
        <>
          <Box className="stepper-container-horizontal">
            {isOnStep1 && (
              <Alert
                info
                dismissible
                content={
                  <span>
                    <strong>IMPORTANT: </strong>
                    Please do not exit this application during the initial setup
                  </span>
                }
                style={{ marginBottom: 20 }}
              />
            )}
            <Header as="h2" content="Initial Setup" style={{ marginBottom: 20 }} />
            <Flex gap="gap.large" style={{ marginBottom: 20 }}>
              <Stepper
                direction="vertical"
                currentStepNumber={currentStep - 1}
                steps={ONBOARDING_STEPS_OC}
                stepColor="#6264a7"
              />
              <FlexItem>
                <Box style={{ width: '100%' }}>
                  <ErrorBox {...errorBoxProps} />
                  {isOnStep1 && <Step1 {...step1Props} />}
                  {isOnStep2 && <Step2 {...stepProps} />}
                  {isOnStep3 && <Step3 {...Step3Props} />}
                </Box>
              </FlexItem>
            </Flex>
          </Box>
          <Box className="buttons-container">
            {isOnStep1 && (
              <Button
                disabled={selectedCountry?.length === 0}
                onClick={handleNextClick}
                icon={isCountriesLoading && <Loader size="smallest" />}
                content="Submit"
                primary
              />
            )}
            {isOnStep3 && isCompleted && !withError && (
              <div className="buttons-container">
                <Button onClick={() => history.push('/tab')} content="Go to application" primary />
              </div>
            )}
            {!isCompleted && withError && (
              <Flex hAlign="center">
                <FlexItem>
                  <>
                    <Button
                      onClick={handleRetryProcess}
                      disabled={isCountriesLoading || isRetrySetupLoading}
                      icon={(isCountriesLoading || isRetrySetupLoading) && <Loader size="smallest" />}
                      content="Retry"
                      primary
                    />
                    <Button
                      onClick={() => downloadTenantData()}
                      disabled={isDownloadingTenantData}
                      icon={isDownloadingTenantData && <Loader size="smallest" />}
                      content="Download logs"
                      secondary
                    />
                  </>
                </FlexItem>
              </Flex>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default OperatorConnectSetup;
