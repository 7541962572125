import { Box, Carousel as FluentCarousel, Image } from '@fluentui/react-northstar';
import { Step2Type } from 'types';

import step1 from 'images/fqdnSteps/step1.png';
import step2 from 'images/fqdnSteps/step2.png';
import step3 from 'images/fqdnSteps/step3.png';
import step4 from 'images/fqdnSteps/step4.png';
import step5 from 'images/fqdnSteps/step5.png';
import step6 from 'images/fqdnSteps/step6.png';
import step7 from 'images/fqdnSteps/step7.png';

import Content from './Content';

const Carousel = (): React.ReactElement => {
  const imageAltTags: { [key: string]: string } = {
    step1: 'FQDN Step 1',
    step2: 'FQDN Step 2',
    step3: 'FQDN Step 3',
    step4: 'FQDN Step 4',
    step5: 'FQDN Step 5',
    step6: 'FQDN Step 6',
    step7: 'FQDN Step 7',
  };

  const carouselItems = [
    {
      key: imageAltTags.step1,
      id: imageAltTags.step1,
      content: (
        <>
          <p>a) Using a Global Administrator account in Office 365 sign into the O365 Tenants Admin portal</p>
          <Image src={step1} alt={imageAltTags.step1} />
        </>
      ),
      'aria-label': imageAltTags.step1,
    },
    {
      key: imageAltTags.step2,
      id: imageAltTags.step2,
      content: (
        <>
          <p>b) Go to Setup then Domains</p>
          <Image src={step2} alt={imageAltTags.step2} />
        </>
      ),
      'aria-label': imageAltTags.step2,
    },
    {
      key: imageAltTags.step3,
      id: imageAltTags.step3,
      content: (
        <>
          <p>
            c) Select the domain name here from the Domains list and press the vertical triple dot (could be the actual
            button image) to show more actions
          </p>
          <Image src={step3} alt={imageAltTags.step3} />
        </>
      ),
      'aria-label': imageAltTags.step3,
    },
    {
      key: imageAltTags.step4,
      id: imageAltTags.step4,
      content: (
        <>
          <p>d) Once selected click "Continue Setup"</p>
          <Image src={step4} alt={imageAltTags.step4} />
        </>
      ),
      'aria-label': imageAltTags.step4,
    },
    {
      key: imageAltTags.step5,
      id: imageAltTags.step5,
      content: (
        <>
          <p>e) Click the 'Continue' button</p>
          <Image src={step5} alt={imageAltTags.step5} />
        </>
      ),
      'aria-label': imageAltTags.step5,
    },
    {
      key: imageAltTags.step6,
      id: imageAltTags.step6,
      content: (
        <>
          <p>
            f) In the 'Add DNS records' page, un-check the 'Exchange and Exchange Online Protection' box. Expand the
            'Advanced Options' section and check the 'Skype for Business' box and the click the 'Continue' button
          </p>
          <Image src={step6} alt={imageAltTags.step6} />
        </>
      ),
      'aria-label': imageAltTags.step6,
    },
    {
      key: imageAltTags.step7,
      id: imageAltTags.step7,
      content: (
        <>
          <p>g) This should now take you to the 'Domain setup is complete' page</p>
          <Image src={step7} alt={imageAltTags.step7} />
        </>
      ),
      'aria-label': imageAltTags.step7,
    },
  ];

  return (
    <Box>
      <h4>To add the FQDN you will need to follow the below steps:</h4>
      <FluentCarousel
        ariaRoleDescription="carousel"
        ariaLabel="Portrait collection"
        navigation={{
          'aria-label': 'people portraits',
          items: carouselItems.map(({ id }: { id: string }) => ({
            key: id,
            'aria-label': imageAltTags[id],
            'aria-controls': id,
          })),
        }}
        style={{ width: '100%' }}
        items={carouselItems}
        getItemPositionText={(index, size) => `${index + 1} of ${size}`}
      />
    </Box>
  );
};

const SubContent = () => (
  <>
    <p>
      Fully Qualified Domain Names (FQDNs) are used to bridge your Microsoft Teams Calling environment with our Carrier
      Hosted Session Boarder Controllers (SBCs), they are used to enable you to make and receive calls.
    </p>
    <p>
      The setup will create the required FQDNs to your tenant, add then add the required DNS records in in our Azure.
    </p>
    <p>
      Once completed you will need to sign into your O365 Admin Centre to complete the final component of Domain setup
      (instructions will show once ready).
    </p>
  </>
);

const Step2 = ({ progressContent, progressValue, withError }: Step2Type): React.ReactElement => {
  const commonProps = {
    progressValue,
    withError,
  };

  return (
    <>
      <Content header="Add FQDN" content={progressContent} subContent={<SubContent />} {...commonProps} />
      {progressValue === 100 && (
        <Box style={{ marginBottom: 20 }}>
          <Carousel />
        </Box>
      )}
    </>
  );
};

export default Step2;
