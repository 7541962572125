import { configureStore } from '@reduxjs/toolkit';
import { batchUpdateApi } from './services/batchUpdateApi';
import { countryApi } from './services/countryApi';
import { dialPlanApi } from './services/dialPlanApi';
import { identityApi } from './services/identityApi';
import { importApi } from './services/importApi';
import { numberApi } from './services/numberApi';
import { onBoardingApi } from './services/onBoardingApi';
import { voicePolicyApi } from './services/voicePolicyApi';
import { bannerApi } from './services/bannerApi';

export const store = configureStore({
  reducer: {
    [batchUpdateApi.reducerPath]: batchUpdateApi.reducer,
    [countryApi.reducerPath]: countryApi.reducer,
    [dialPlanApi.reducerPath]: dialPlanApi.reducer,
    [identityApi.reducerPath]: identityApi.reducer,
    [numberApi.reducerPath]: numberApi.reducer,
    [importApi.reducerPath]: importApi.reducer,
    [onBoardingApi.reducerPath]: onBoardingApi.reducer,
    [voicePolicyApi.reducerPath]: voicePolicyApi.reducer,
    [bannerApi.reducerPath]: bannerApi.reducer,
  },
  middleware: (getDefaultMiddlware) =>
    getDefaultMiddlware().concat([
      batchUpdateApi.middleware,
      countryApi.middleware,
      dialPlanApi.middleware,
      identityApi.middleware,
      numberApi.middleware,
      importApi.middleware,
      onBoardingApi.middleware,
      voicePolicyApi.middleware,
      bannerApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
