/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react';
import { Flex, Loader } from '@fluentui/react-northstar';

import { getCallerIds } from 'redux/services/callerIdApi';
import { useGetIndentitiesMutation } from 'redux/services/identityApi';
import { AuthContext } from 'contexts/AuthContext';
import { useQuery } from 'hooks/useQuery';

import { CallerId, CallerIdList } from 'types';

import Dialog from './Dialog';
import ErrorBox from './ErrorBox';
import Table from './Table';
import Toolbar from './Toolbar';

import Pagination from 'components/common/Pagination';
import VersionNumber from 'components/VersionNumber';

const ManageCallerIdPage = (): React.ReactElement => {
  const { token } = useContext(AuthContext);

  const {
    data: callerIds,
    error,
    refetch,
    loading,
  } = useQuery<CallerIdList>(() => getCallerIds({ token, page: currentPage, limit: 10 }), !!token);

  const [getIdentities, { data: identities, isLoading: isIdentitiesLoading }] = useGetIndentitiesMutation();

  const [selectedCallerId, setSelectedCallerId] = useState<CallerId>();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [callerIdsWithAccount, setCallerIdsWithAccount] = useState<CallerId[]>();

  useEffect(() => {
    if (!token) return;
    refetch();
  }, [currentPage, token]);

  useEffect(() => {
    if (!token) return;
    /* @TODO: Get top 100 resource type UPNs for now.
     * Get this list from a new endpoint where UPNs are already filtered */
    getIdentities({
      token,
      body: {
        page: 1,
        limit: 100,
        search: '',
        identityFilter: [1],
        numberFilter: [0, 1],
        voiceRouteFilter: [0, 1],
        dialPlanFilter: [0, 1],
        licenseFilter: [0, 1],
      },
    });
  }, [token]);

  useEffect(() => {
    if (callerIds?.data && !isIdentitiesLoading) {
      const callerIdsWithAccount = callerIds?.data.map((val) => {
        const upn = identities?.data.find(
          (u: { identity: string }) => typeof val.upn === 'object' && u.identity === val.upn?.userPrincipalName,
        );
        return { ...val, upn };
      });
      setCallerIdsWithAccount(callerIdsWithAccount);
    }
  }, [callerIds, identities]);

  const handleCloseDialog = () => {
    setSelectedCallerId(undefined);
    setIsDetailsOpen(false);
  };

  const handleRefreshClick = () => {
    refetch();
    setIsDetailsOpen(false);
  };

  const limit = 10;

  const tableProps = {
    callerIds,
    callerIdsWithAccount,
    setIsDetailsOpen,
    setSelectedCallerId,
  };

  const toolbarProps = {
    loading,
    handleRefreshClick,
    setIsDetailsOpen,
    setSelectedCallerId,
  };

  return (
    <>
      <Flex gap="gap.small" vAlign="center" className="app-toolbar">
        <Toolbar {...toolbarProps} />
      </Flex>
      {error && <ErrorBox error={error} />}
      {loading ? (
        <Loader />
      ) : (
        <>
          <Table {...tableProps} />
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={callerIds?.totalCount ?? 0}
            pageSize={limit}
            onPageChange={(page: number) => setCurrentPage(page)}
          >
            <VersionNumber />
          </Pagination>
        </>
      )}
      {isDetailsOpen && !isIdentitiesLoading && (
        <Dialog
          isOpen={isDetailsOpen}
          onCancel={handleCloseDialog}
          onConfirm={handleRefreshClick}
          selectedCallerId={selectedCallerId}
          usersList={identities?.data}
        />
      )}
    </>
  );
};

export default ManageCallerIdPage;
