import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Button, Toolbar as FluentToolbar } from '@fluentui/react-northstar';
import { ChevronStartIcon, RetryIcon, SaveIcon } from '@fluentui/react-icons-northstar';
import { useAppContext } from 'contexts/AppContext';

type operatorConnectCount = {
  [key: string]: number;
};

type StepsType = {
  currentStep: number;
  isCompleted: boolean;
  selectedCountry: string[];
  withError: boolean;
  setCurrentCount: Dispatch<SetStateAction<number>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  setProgressValue: Dispatch<SetStateAction<number>>;
  setWithError: Dispatch<SetStateAction<boolean>>;
};

const Toolbar = ({
  currentStep,
  isCompleted,
  selectedCountry,
  withError,
  setCurrentCount,
  setCurrentStep,
  setErrorMessage,
  setProgressValue,
  setWithError,
}: StepsType): React.ReactElement => {
  const history = useHistory();
  const { tenantData } = useAppContext();

  const isOperatorConnect = tenantData?.serviceType === 'OperatorConnect';

  const OC_STEP_COUNT: operatorConnectCount = {
    1: 1,
    2: 4,
  };

  const currentOCStep = isOperatorConnect ? OC_STEP_COUNT[currentStep] : currentStep;

  const handleNextClick = () => setCurrentStep(currentStep + 1);

  const resetState = () => {
    setProgressValue(0);
    setCurrentCount(0);
  };

  const resetError = () => {
    setErrorMessage('');
    setWithError(false);
  };

  const handleRetrySetup = () => {
    resetError();
    resetState();
  };

  return (
    <Flex gap="gap.small" vAlign="center" className="app-toolbar">
      <Flex.Item>
        <Button
          content="Add Region(s)"
          icon={<ChevronStartIcon />}
          text
          data-testid="btn-back"
          disabled={[2, 3].includes(currentOCStep)}
          onClick={() => history.push('/tab')}
        />
      </Flex.Item>
      <Flex.Item push>
        <section>
          <FluentToolbar
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'end',
            }}
            aria-label="Default"
            items={[
              {
                icon: !isCompleted && withError && (
                  <Button content="Retry" icon={<RetryIcon />} onClick={handleRetrySetup} text />
                ),
                key: 'retry',
              },
              {
                icon: (
                  <Button
                    content="Save changes"
                    icon={<SaveIcon />}
                    disabled={currentStep !== 1 || !selectedCountry?.length}
                    onClick={handleNextClick}
                    text
                  />
                ),
                key: 'save',
              },
            ]}
          />
        </section>
      </Flex.Item>
    </Flex>
  );
};

export default Toolbar;
