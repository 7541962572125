/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Flex, Loader, Header } from '@fluentui/react-northstar';
import { AcceptIcon, ErrorIcon } from '@fluentui/react-icons-northstar';

import { checkOnboardingProcess, useGetOnboardingProgressMutation } from 'redux/services/onBoardingApi';
import { useGetBannersMutation } from 'redux/services/bannerApi';

import { useMutation } from 'hooks/useMutation';

import { AuthContext } from 'contexts/AuthContext';
import { AppContext } from 'contexts/AppContext';

import { decodeErrorMsg } from 'helpers/utils';
import {
  OnboardingProcess,
  DecodedErrorMessage,
  ProgressDataType,
  ProgressType,
  StepType,
  OnboardingStep,
  ErrorTypes,
} from 'types';

import { APP_INSTANCE, PERMISSIONS_ERROR_MESSAGE, INITIAL_PROGRESS } from '../../constants';

import Authorize from './Authorize';
import Status from './Status';
import { InvalidPermissionsError, MissingTenantError, UnknownStepError } from './Errors';
import OnboardingCarousel from './OnboardingCarousel';

const MISSING_TENANT_FIELDS = {
  ucaas: {
    label: 'Symbio Enterprise Calling',
    url: 'https://mnfenterprise.com.au/cloud-collaboration/teams',
  },
  taas: {
    label: 'Unite Calling',
    url: 'https://www.telcoinabox.com.au/products/teams-direct-routing',
  },
  general: {
    label: 'Symbio Enterprise Calling',
    url: 'https://mnfenterprise.com.au/cloud-collaboration/teams',
  },
};

const ERROR_MESSAGES = {
  [ErrorTypes.MISSING_TENANT]: MissingTenantError,
  [ErrorTypes.NOT_CONFIGURED]: UnknownStepError,
  [ErrorTypes.APP_PERMISSIONS_ERROR]: InvalidPermissionsError,
};

const Onboarding = (): React.ReactElement => {
  const { token, setAuthorizationError } = useContext(AuthContext);
  const { isInTeams } = useContext(AppContext);
  const { togglePermissionsCheckComplete, isPermissionsCheckComplete, updateOnboardingProgress } =
    useContext(AppContext);

  const history = useHistory();

  const [continueMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState<string | React.ReactNode | undefined | null>(null);
  const [errorSubMsg, setErrorSubMsg] = useState('');
  const [isError, setIsError] = useState(false);
  const [adsViewedCount, setAdsViewedCount] = useState(1);
  const [timeLeft, setTimeLeft] = useState(0);
  const [progress, setProgress] = useState<ProgressType>(INITIAL_PROGRESS);
  const [showPermission, setShowPermission] = useState(false);

  const [getProgress, { isLoading: isProgressLoading }] = useGetOnboardingProgressMutation();
  const [getBanners, { data: banners, isLoading: isGetBannersLoading }] = useGetBannersMutation();

  const currentStep = useRef(0);

  const mainBanners = banners?.filter(({ isMainBanner }: { isMainBanner: boolean }) => isMainBanner) ?? [];

  const isCompleted = progress?.step2 === 'done' && !isPermissionsCheckComplete;

  const isBannerVisible = !isGetBannersLoading && progress?.step1 === 'done';

  const setErrorInfo = (step: StepType, msg = '', info = '', tenantId?: string) => {
    switch (step) {
      case 1:
        if (msg?.toLowerCase()?.includes('tenant not found')) {
          const instanceMsg = ERROR_MESSAGES[ErrorTypes.MISSING_TENANT]({
            tenantId,
            ...MISSING_TENANT_FIELDS[APP_INSTANCE],
          });
          setErrorMsg(instanceMsg);
          return;
        }
        setErrorMsg(`${msg}. Please contact your Admin for more support.`);
        break;
      case 2: {
        if (!isInTeams) {
          const instanceMsg = ERROR_MESSAGES[ErrorTypes.APP_PERMISSIONS_ERROR]({
            tenantId,
          });
          setErrorMsg(instanceMsg);
          setShowPermission(true);
          return;
        }
        if (!setAuthorizationError) return;
        setAuthorizationError((context) => {
          setShowPermission(true);
          if (
            context.subEntityId === 'afterAuthorize' &&
            msg.includes('We need an administrator to consent to use the application.')
          ) {
            setErrorMsg(PERMISSIONS_ERROR_MESSAGE);
            setErrorSubMsg(PERMISSIONS_ERROR_MESSAGE);
            return;
          }
          setErrorMsg(`${msg}`);
          setErrorSubMsg(`${info}`);
        });
        break;
      }
    }
  };

  const setFailed = (step: StepType) => {
    setProgress({ ...progress, [`step${step}`]: 'failed' });
    setIsError(true);
  };

  const { mutate: checkOnboardingStep } = useMutation<number, OnboardingProcess>({
    onSuccess: (res: OnboardingProcess) => {
      const { current } = currentStep ?? 0;
      const stepCount = `step${current}`;
      const errorMessage = decodeErrorMsg(res.message) as DecodedErrorMessage;

      if (res.error) {
        setFailed(current);
        setErrorInfo(current, errorMessage?.short_description || res.message, undefined, res.error.tenantId);
        return;
      }

      setProgress({ ...progress, [stepCount]: 'done' });
    },
    onFailure: (err: string) => {
      const { current } = currentStep ?? 0;
      const error = decodeErrorMsg(err) as DecodedErrorMessage;

      setFailed(current);
      setErrorInfo(current, error.short_description, error.error_description);
    },
    callback: checkOnboardingProcess,
  });

  const setOnboardingProgress = useCallback(
    (result: ProgressDataType) => {
      if (updateOnboardingProgress) {
        updateOnboardingProgress(result);
      }

      if (togglePermissionsCheckComplete) {
        togglePermissionsCheckComplete(true);
      }
    },
    [togglePermissionsCheckComplete, updateOnboardingProgress],
  );

  const setOnboardingInfo = useCallback(async () => {
    const stepCount = currentStep.current === 1 ? 'step1' : 'step2';

    if (progress[stepCount] !== 'pending') return;

    setProgress({ ...progress, [stepCount]: 'ongoing' });
    await checkOnboardingStep(currentStep.current);
  }, [checkOnboardingStep, progress]);

  useEffect(() => {
    getBanners({ token });
  }, []);

  useEffect(() => {
    if (!isCompleted && timeLeft === 0) return;

    if (timeLeft === 0) {
      onSubmit();
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    if (!token) return;

    const { step1, step2 } = progress;

    if (step1 === 'pending') {
      currentStep.current = 1;
      setOnboardingInfo();
      return;
    }

    if (step1 === 'done' && step2 === 'pending') {
      currentStep.current = 2;
      setOnboardingInfo();
      return;
    }
  }, [progress, token, currentStep, setOnboardingInfo]);

  useEffect(() => {
    if (isCompleted) {
      setTimeLeft(5);
    }
  }, [isCompleted]);

  const onSubmit = () => {
    if (togglePermissionsCheckComplete) togglePermissionsCheckComplete(true);
    getProgress({ token }).then((result) => {
      const response = result as { data: ProgressDataType };

      setOnboardingProgress(response.data);
      const { data } = response;

      if (data?.tenantStatus === 2 && data?.onboardingStep === OnboardingStep.NONE) {
        // onboarding completed and no ongoing
        history.push('/tab');
      } else if (data?.tenantStatus === 2 && data?.onboardingStep !== OnboardingStep.NONE) {
        // onboarding completed and has ongoing, probably retriggered onboarding
        history.push('/setting');
      } else {
        // initial onboarding
        history.push('/setup');
      }
    });
  };

  const authorizeProps = {
    continueMsg,
    showPermission,
  };

  return (
    <Box style={{ marginTop: 30 }}>
      {isBannerVisible && (
        <OnboardingCarousel
          adsViewedCount={adsViewedCount}
          setAdsViewedCount={setAdsViewedCount}
          banners={mainBanners}
        />
      )}
      <div
        style={{
          margin: '50px auto',
          width: 250,
          textAlign: 'center',
        }}
      >
        {!isCompleted ? (
          isError ? (
            <>
              <ErrorIcon size="larger" color="red" />
              <Header content={errorMsg} as="h4" />
              <Header content={errorSubMsg} as="h6" />
            </>
          ) : (
            <>
              <Loader />
              <Header content={isProgressLoading ? 'Loading...' : ''} as="h4" />
            </>
          )
        ) : (
          <>
            <AcceptIcon size="larger" style={{ color: '#237b4b' }} />
            <Header content={`Redirecting to the application in ${timeLeft} sec`} as="h4" />
          </>
        )}
      </div>
      <Flex column id="app-onboarding">
        <Status progress={progress} />
        <Authorize {...authorizeProps} />
        {!isError && (
          <Button
            primary
            disabled={!isCompleted}
            onClick={onSubmit}
            content="Go to application"
            style={{
              margin: '0 auto',
            }}
          />
        )}
      </Flex>
    </Box>
  );
};

export default Onboarding;
