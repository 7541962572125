/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AcceptIcon,
  Box,
  Button,
  ErrorIcon,
  Flex,
  Header,
  List,
  ListProps,
  Loader,
  Status,
} from '@fluentui/react-northstar';
import { AuthContext } from 'contexts/AuthContext';
import { useQuery } from 'hooks/useQuery';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getOrganizations } from 'redux/services/tenantsApi';

const Partners = (): React.ReactElement => {
  const { tenant, setTenant, token, accountInfo } = useContext(AuthContext);
  const [selectedTenant, setSelectedTenant] = useState<any>(null);
  const { data: organizations, loading: orgLoading, error } = useQuery(() => getOrganizations());
  const history = useHistory();
  const { search } = useLocation();

  const selectTenant = () => {
    if (selectedTenant) {
      setTenant(selectedTenant?.key);
    }
  };

  const listItems = (organizations || []).map((org: any) => {
    const med = [1, 2].includes(org.status) ? (
      <Status state="success" icon={<AcceptIcon />} />
    ) : (
      <Status state="error" icon={<ErrorIcon />} />
    );
    const cont = (
      <Flex style={{ justifyContent: 'center', marginRight: '0.5rem', lineHeight: '20px', fontSize: '14px' }}>
        <Box style={{ marginRight: '0.5rem' }}>{med}</Box>
        <Box style={{ marginRight: '0.5rem' }}>{org.displayName}</Box>
      </Flex>
    );
    return { key: org.id, content: cont };
  });

  const indexChanged = (event: React.SyntheticEvent<HTMLElement>, data?: ListProps) => {
    if (data?.items && data?.selectedIndex != null) {
      setSelectedTenant(data.items[data.selectedIndex]);
    }
  };

  useEffect(() => {
    if (!organizations) return;
    if (organizations.length === 1) {
      setTenant(organizations[0].id);
      return;
    }
  }, [organizations, setTenant]);

  // Check if tenant orgs are loaded and if there is an error
  useEffect(() => {
    // Don't do anything if tenant is already set or if orgs are still loading
    if (orgLoading || tenant) return;
    // If there is an error and tenant is not set, set tenant to current logged in tenant
    if (error && accountInfo?.tenantId && !tenant) {
      setTenant(accountInfo.tenantId);
      history.push('/');
    }
  }, [orgLoading, error, accountInfo, tenant, setTenant]);

  useEffect(() => {
    if (token) {
      const query = new URLSearchParams(window.location.search);
      const tid = query.get('tenant');
      if (tid && tid !== tenant) {
        setTenant(tid);
        return;
      }
      if (tenant) {
        history.push('/');
      }
    } else {
      history.push('/login');
    }
  }, [history, token, tenant, setTenant, search]);

  console.log(error);

  return (
    <>
      {orgLoading ? (
        <Loader></Loader>
      ) : (
        <Flex hAlign="center">
          <Flex key="center" hAlign="center" style={{ width: 350, padding: 20, textAlign: 'center' }}>
            <Box>
              <Header color="brand" content="Tenants" />
              <List items={listItems} selectable={true} onSelectedIndexChange={indexChanged}></List>
              <Button
                content="Select Tenant"
                primary
                onClick={selectTenant}
                disabled={!selectedTenant}
                style={{ display: 'block', margin: 'auto', marginTop: 16 }}
              />
            </Box>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default Partners;
