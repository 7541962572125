/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

export type GetDataResponse<T> = {
  loading?: boolean;
  error?: string;
  data: T;
  refetch: () => Promise<void>;
  enableQuery: Dispatch<SetStateAction<boolean>>;
};

/**
 * Helper hook to fetch async data
 * @param callback Function to fetch resource and returns Promise<T>
 * @returns { loading: boolean, error: string, data: T, refetch: () => Promise<void> }
 */
export function useQuery<T>(callback: () => Promise<T>, enabled = true, runOnInit = true): GetDataResponse<T> {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [data, setData] = useState<T>();
  const [isEnabled, setEnabled] = useState(enabled);
  const initialized = useRef(false);

  const getData = async () => {
    if (loading || !isEnabled) return;
    setLoading(true);
    setData(undefined);
    setError(undefined);
    try {
      const result = await callback();
      setData(result);
    } catch (err) {
      const errorMessage = (err as Error)?.message ?? err;
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialized.current === false && runOnInit === false) {
      initialized.current = true;
      return;
    }

    if (isEnabled) {
      getData();
    }
  }, [isEnabled]);

  return {
    loading,
    error,
    data,
    refetch: getData,
    enableQuery: setEnabled,
  } as GetDataResponse<T>;
}
