/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AccountInfo, EndSessionPopupRequest, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { TeamsUserCredential, loadConfiguration, ResourceType } from '@microsoft/teamsfx';
import * as microsoftTeams from '@microsoft/teams-js';
import jwtDecode from 'jwt-decode';
import { AppContext } from './AppContext';
import { msalConfig } from 'authConfig';
import { deepRefresh, getBaseTenant, getTenant } from 'helpers/utils';

const teamsfxEndpoint = process.env.REACT_APP_TEAMSFX_ENDPOINT;
const startLoginPageUrl = process.env.REACT_APP_START_LOGIN_PAGE_URL;
const functionEndpoint = process.env.REACT_APP_FUNC_ENDPOINT;
const clientId = process.env.REACT_APP_CLIENT_ID;
const TOKEN_REFRESH_INTERVAL_IN_MS = parseInt(process.env.REACT_APP_TOKEN_REFRESH_INTERVAL_IN_MS ?? '300000', 10);

export const loginRequest = {
  scopes: [
    'openid',
    'User.Read',
    'AppCatalog.ReadWrite.All',
    'Directory.AccessAsUser.All',
    'Directory.ReadWrite.All',
    'Domain.ReadWrite.All',
    'email',
    'Group.ReadWrite.All',
    'profile',
    'User.Read.All',
    'https://api.interfaces.records.teams.microsoft.com/user_impersonation',
  ],
};

const initTeamsConfiguration = () => {
  loadConfiguration({
    authentication: {
      initiateLoginEndpoint: startLoginPageUrl,
      simpleAuthEndpoint: teamsfxEndpoint,
      clientId: clientId,
    },
    resources: [
      {
        type: ResourceType.API,
        name: 'default',
        properties: {
          endpoint: functionEndpoint,
        },
      },
    ],
  });
};

export const azureMsConfig = { ...msalConfig };
if (azureMsConfig.auth.redirectUri) {
  const redirectUrl = new URL(azureMsConfig.auth.redirectUri);
  azureMsConfig.auth.redirectUri = redirectUrl?.origin ?? '';
}

export const msalInstance = new PublicClientApplication(azureMsConfig);
msalInstance.enableAccountStorageEvents();

enum AuthenticationState {
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
}

type AuthStateProps = {
  accountInfo?: AccountInfo | null;
  authenticationState: AuthenticationState;
  error?: string;
  token?: string;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  acquirePermissions?: () => Promise<void>;
  setAuthorizationError?: (callback: (context: microsoftTeams.Context) => void) => void;
  tenant?: string;
  organizations?: { id: string; displayName: string }[];
  orgLoading?: boolean;
  setTenant: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const AuthContext = React.createContext<AuthStateProps>({
  authenticationState: AuthenticationState.Unauthenticated,
  login: async () => {},
  logout: async () => {},
  setTenant: () => {},
});

type TokenInfo = {
  name: string;
  preferred_username: string;
  tid: string;
};

const loginToTeams = async (): Promise<string> => {
  return new Promise((resolve) => {
    microsoftTeams.authentication.getAuthToken({
      successCallback: (token: string) => {
        microsoftTeams.appInitialization.notifySuccess();
        resolve(token);
      },
      failureCallback: (message: string) => {
        microsoftTeams.appInitialization.notifyFailure({
          reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
          message,
        });
        resolve('');
      },
    });
  });
};

const removeAppToken = (): boolean => {
  if (!window?.localStorage) return false;
  if (window.localStorage.getItem('SYMBIO_MS_TOKEN')) {
    window.localStorage.removeItem('SYMBIO_MS_TOKEN');
  }
  if (window.localStorage.getItem('SYMBIO_MS_TOKEN_EXP')) {
    window.localStorage.removeItem('SYMBIO_MS_TOKEN_EXP');
  }
  return true;
};

const persistAppToken = (token: string) => {
  if (removeAppToken()) {
    window.localStorage.setItem('SYMBIO_MS_TOKEN', token);
    /* Set token to expire in 10 minutes. This is only checked during initial load
     * as the token will be refreshed every 5 mins once logged in */
    const exp = new Date();
    exp.setMinutes(new Date().getMinutes() + 30);
    window.localStorage.setItem('SYMBIO_MS_TOKEN_EXP', exp.getTime().toString());
  }
};

const persistAppTenant = (tenant: string) => {
  if (window?.localStorage) {
    window.localStorage.setItem('XTENANT', tenant);
  }
};

const removeAppTenant = () => {
  if (window?.localStorage) {
    window.localStorage.removeItem('XTENANT');
  }
};

const persistBaseTenant = (tenant: string) => {
  if (window?.localStorage) {
    window.localStorage.setItem('BASETENANT', tenant);
  }
};

const removeBaseTenant = () => {
  if (window?.localStorage) {
    window.localStorage.removeItem('BASETENANT');
  }
};

const getAppToken = () => {
  if (!window?.localStorage) return undefined;
  const item = window.localStorage.getItem('SYMBIO_MS_TOKEN');
  if (!item) return undefined;
  const exp = window.localStorage.getItem('SYMBIO_MS_TOKEN_EXP');
  if (!exp) return undefined;
  if (Date.now() >= parseInt(exp, 10)) return undefined;
  return item;
};

const AuthContextProvider: React.FC = ({ children }) => {
  const accountInfo = useRef<AccountInfo>();
  const { isInTeams, enableGetTenantData } = useContext(AppContext);
  const [token, setToken] = useState('');
  const [tenant, setTenant] = useState<string | undefined>();
  const persistedToken = getAppToken();
  const [error, setError] = useState<string>();

  const acquirePermissions = async (): Promise<void> => {
    const permissions = [
      '00000003-0000-0000-c000-000000000000/AppCatalog.ReadWrite.All',
      '00000003-0000-0000-c000-000000000000/Group.ReadWrite.All',
      '00000003-0000-0000-c000-000000000000/User.Read.All',
      '48ac35b8-9aa8-4d74-927d-1f4a14a0b239/user_impersonation',
      'User.Read',
      '00000002-0000-0000-c000-000000000000/Directory.AccessAsUser.All',
      '00000002-0000-0000-c000-000000000000/Directory.ReadWrite.All',
      '00000002-0000-0000-c000-000000000000/Domain.ReadWrite.All',
      '00000003-0000-0000-c000-000000000000/DelegatedAdminRelationship.Read.All',
    ];

    if (isInTeams) {
      try {
        permissions.push(`${azureMsConfig.auth.clientId}/access_as_user`);
        const { scope } = { scope: permissions };
        initTeamsConfiguration();
        const credential = new TeamsUserCredential();
        await credential.login(scope);
        deepRefresh();
      } catch (error) {
        console.error(error);
      } finally {
        deepRefresh();
      }
    } else {
      if (getTenant() !== getBaseTenant()) {
        const redirecturi = encodeURIComponent(azureMsConfig.auth.redirectUri);
        const clientId = azureMsConfig.auth.clientId;
        window.location.href = `https://login.microsoftonline.com/${getTenant()}/adminconsent?client_id=${clientId}&redirect_uri=${redirecturi}`;
      } else {
        await msalInstance.acquireTokenSilent({
          scopes: permissions,
        });
      }
    }
  };

  const updateCurrentToken = (updatedToken: string, user?: AccountInfo) => {
    persistAppToken(updatedToken);
    setToken(updatedToken);
    if (user) {
      accountInfo.current = user;
      return;
    }
    const tokenInfo = jwtDecode<TokenInfo>(updatedToken);
    if (!tokenInfo) return;
    accountInfo.current = {
      username: tokenInfo.preferred_username,
      name: tokenInfo.name,
      tenantId: tokenInfo.tid,
    } as AccountInfo;
    persistBaseTenant(tokenInfo.tid);
  };

  const loginViaTeams = async () => {
    const teamsToken = await loginToTeams();
    if (!teamsToken) return;
    updateCurrentToken(teamsToken);
  };

  const loginViaMsal = async () => {
    if (persistedToken) return;
    try {
      const result = await msalInstance.loginPopup(loginRequest);
      if (!result?.idToken) return;
      updateCurrentToken(result.idToken);
    } catch (e) {
      const err = JSON.parse(JSON.stringify(e)) as { errorMessage: string };
      const message = err?.errorMessage?.includes('\r\n')
        ? err.errorMessage.split('\r\n')?.[0]?.split(':')?.[1]
        : err.errorMessage;
      setError(message);
    }
  };

  const refreshTokenMsal = async () => {
    if (!accountInfo.current) return;
    const account = msalInstance.getAccountByUsername(accountInfo.current.username);
    if (!account) return;
    const result = await msalInstance.acquireTokenSilent({ ...loginRequest, account });
    updateCurrentToken(result.idToken, account);
  };

  const login = async () => {
    if (isInTeams) {
      await loginViaTeams();
    } else {
      await loginViaMsal();
    }
  };

  const logout = async () => {
    if (isInTeams || !token || !accountInfo.current) return;
    const logoutRequest: EndSessionPopupRequest = {
      account: msalInstance.getAccountByUsername(accountInfo.current.username),
      mainWindowRedirectUri: '/',
    };
    await msalInstance.logoutPopup(logoutRequest);
    removeAppToken();
    removeAppTenant();
    removeBaseTenant();
  };

  const setAuthorizationError = (callback: (context: microsoftTeams.Context) => void) => {
    if (!isInTeams) return;
    microsoftTeams.getContext(callback);
  };

  useEffect(() => {
    if (!persistedToken) return;
    if (isInTeams) {
      removeAppToken();
      return;
    }
    updateCurrentToken(persistedToken);
  }, []);

  useEffect(() => {
    if (isInTeams) {
      loginViaTeams();
    }
    const ctr = setInterval(() => {
      if (isInTeams) loginViaTeams();
      else refreshTokenMsal();
    }, TOKEN_REFRESH_INTERVAL_IN_MS);
    return () => clearInterval(ctr);
  }, [isInTeams]);

  useEffect(() => {
    if (tenant) {
      persistAppTenant(tenant);
      enableGetTenantData(true);
    }
  }, [tenant]);

  const value = {
    accountInfo: accountInfo.current,
    authenticationState: AuthenticationState.Authenticated,
    error,
    token,
    login,
    logout,
    acquirePermissions,
    setAuthorizationError,
    tenant,
    setTenant,
  };

  return (
    <>
      {isInTeams ? (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      ) : (
        <MsalProvider instance={msalInstance}>
          <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
        </MsalProvider>
      )}
    </>
  );
};

export default AuthContextProvider;
