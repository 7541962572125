import { useState, useContext, useMemo } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog as FluentDialog,
  DropdownProps,
  Flex,
  Form as FluentForm,
  FormInput,
  FormLabel,
  Loader,
} from '@fluentui/react-northstar';

import { createCallerId, updateCallerId } from 'redux/services/callerIdApi';
import { useMutation } from 'hooks/useMutation';
import { useIsMobileView } from 'hooks/useIsMobileView';

import { AuthContext } from 'contexts/AuthContext';

import { CallerId, DropdownItemType, DialogType, LabelType, UpnType } from 'types';

import { DEFAULT_FORM_VALUES } from '../../constants';

import ManageDropdown from '../Manage/ManageTable/components/ManageDropdown';

const Label = ({ title }: LabelType) => (
  <>
    {title}:<span style={{ color: '#c4314b' }}> *</span>
  </>
);

const Dialog = (props: DialogType): React.ReactElement => {
  const { isMobile } = useIsMobileView();

  const { token } = useContext(AuthContext);

  const usersList = useMemo<DropdownItemType[] | undefined>(() => {
    return props.usersList
      ?.filter((user) => user.voiceLicenseStatus)
      ?.map((u: UpnType) => ({
        description: u.userPrincipalName,
        content: <Flex space="between">{u.userPrincipalName}</Flex>,
      }));
  }, [props.usersList]);

  const [form, setForm] = useState({
    ...DEFAULT_FORM_VALUES,
    ...props?.selectedCallerId,
  });

  const { mutate, loading, error } = useMutation<CallerId, boolean | string>({
    callback: async (req: CallerId): Promise<boolean | string> => {
      return props.selectedCallerId ? updateCallerId(req, token) : createCallerId(req, token);
    },
    onSuccess: () => {
      if (props.onConfirm) props.onConfirm();
    },
  });

  const handleSubmit = async () => {
    await mutate(form as CallerId);
  };

  const handleResourceChange = (_: React.MouseEvent | React.KeyboardEvent | null, data: DropdownProps) => {
    const value = data?.value as DropdownItemType;
    if (!value) {
      setForm({ ...form, upn: undefined });
    } else {
      const upn = props.usersList?.find((u) => u.userPrincipalName === value.description);
      setForm({ ...form, upn });
    }
  };

  const handleTextChange = (e: React.SyntheticEvent<HTMLElement, Event>) => {
    const { name, value } = e.target as HTMLInputElement;
    setForm({ ...form, [name]: value } as CallerId);
  };

  const isComplete = () => {
    const { callerIdPolicy, companyName, upn }: CallerId = form || {};
    return [callerIdPolicy, companyName, (upn as UpnType)?.userPrincipalName].every((element) => element);
  };

  return (
    <FluentDialog
      className="cli-details-dialog"
      data-testid="dialog-cli-details"
      onCancel={props.onCancel}
      content={
        <>
          <FluentForm className="cli-details-dialog-form" style={{ justifyContent: 'flex-start' }}>
            <h2>{!props.selectedCallerId ? 'Create Caller ID Policy' : 'Update Caller ID Policy'}</h2>
            {error && (
              <Box style={{ marginTop: 8, marginBottom: 8 }}>
                <Alert visible={!!error} danger content={error} style={{ marginBottom: 20, padding: 8 }} />
              </Box>
            )}
            <Box style={{ marginBottom: '1.5rem' }}>
              <FormLabel content={<Label title="Policy" />} style={{ marginBottom: '0.5rem' }} />
              <FormInput
                onChange={handleTextChange}
                placeholder="Policy Name"
                name="callerIdPolicy"
                data-testid="input-caller-id-policy"
                value={form?.callerIdPolicy}
                fluid
                disabled={!!props.selectedCallerId}
              />
            </Box>
            <Box style={{ marginBottom: '1.5rem' }}>
              <FormLabel content={<Label title="Company Name" />} style={{ marginBottom: '0.5rem' }} />
              <FormInput
                onChange={handleTextChange}
                placeholder="Company Name"
                name="companyName"
                data-testid="input-company-name"
                value={form?.companyName}
                fluid
              />
            </Box>
            <Box style={{ marginBottom: '1.5rem' }}>
              <FormLabel content={<Label title="Resource" />} style={{ marginBottom: '0.5rem' }} />
              <ManageDropdown
                disabled={loading}
                items={usersList ?? []}
                onChange={handleResourceChange}
                placeholder="Resource"
                className={`dropdown-user-resource ${
                  (form.upn as UpnType)?.userPrincipalName ? 'has-value' : undefined
                }`}
                value={(form.upn as UpnType)?.userPrincipalName}
              />
            </Box>
            <Box style={{ marginBottom: '1rem', marginTop: 0 }}>
              <Button
                content={
                  <Flex gap="gap.small">
                    {loading && <Loader size="smallest" />}
                    <p>Save</p>
                  </Flex>
                }
                disabled={loading || !isComplete()}
                primary
                onClick={handleSubmit}
                fluid
                data-testid="btn-save"
              />
            </Box>
            <Box style={{ marginBottom: '1rem', marginTop: 0 }}>
              <Button
                content="Cancel"
                disabled={loading}
                secondary
                onClick={props.onCancel}
                fluid
                data-testid="btn-close"
              />
            </Box>
          </FluentForm>
        </>
      }
      open={props.isOpen}
      style={{
        width: isMobile ? '100%' : '400px',
        height: isMobile ? '100%' : 'auto',
        borderRadius: 0,
      }}
    />
  );
};

export default Dialog;
