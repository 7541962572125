import { useAppContext } from 'contexts/AppContext';
import DirectRoutingSetup from './DirectRoutingSetup';
import OperatorConnectSetup from './OperatorConnectSetup';

const Setup = (): React.ReactElement => {
  const { tenantData } = useAppContext();

  if (tenantData?.serviceType === 'OperatorConnect') {
    return <OperatorConnectSetup />;
  }

  return <DirectRoutingSetup />;
};

export default Setup;
