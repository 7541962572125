/* eslint-disable @typescript-eslint/no-explicit-any */
import { mergeThemes, teamsTheme, ThemePrepared } from '@fluentui/react-northstar';
import { getTheme } from 'helpers/themes';
import { useQuery } from 'hooks/useQuery';
import { useTeamsFx } from 'hooks/useTeamsFx';
import React, { useEffect, useMemo, useState } from 'react';
import { getTenantData, getDomainTheme } from 'redux/services/tenantsApi';
import { ProgressDataType, TenantTheme } from 'types';

type AppState = {
  isInTeams?: boolean;
  teamsTheme: string;
  onBoardingProgress?: ProgressDataType;
  updateOnboardingProgress?: (payload: ProgressDataType) => void;
  isPermissionsCheckComplete?: boolean;
  togglePermissionsCheckComplete?: (val?: boolean) => void;
  isInitialized?: boolean;
  tenantTheme?: TenantTheme;
  tenantData?: any;
  appTheme?: ThemePrepared<any>;
  enableGetTenantData: React.Dispatch<React.SetStateAction<boolean>>;
};

const AppContext = React.createContext<AppState>({
  isInTeams: undefined,
  teamsTheme: 'default',
  isPermissionsCheckComplete: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  enableGetTenantData: () => {},
});

const AppContextProvider: React.FC = ({ children }) => {
  const { isInTeams, theme, error, loading, themeString } = useTeamsFx();
  const [isInitialized, setIsInitialized] = useState(false);
  const { data: tenantTheme, loading: isTenantLoading } = useQuery(() => getDomainTheme(window?.location?.hostname));
  const { data: tenantData, enableQuery: enableGetTenantData } = useQuery(() => getTenantData(), false);

  const [onBoardingProgress, setOnBoardingProgress] = useState<ProgressDataType>();
  const [isPermissionsCheckComplete, setIsPermissionsCheckComplete] = useState(false);

  const appTheme = useMemo(() => {
    if (!isInitialized) return undefined;
    const currentTheme = theme || teamsTheme;
    if (!tenantTheme) return currentTheme;
    return !isInTeams
      ? mergeThemes(
          currentTheme,
          getTheme({ primary: tenantTheme.primaryColor, secondary: tenantTheme.secondaryColor }),
        )
      : currentTheme;
  }, [isInTeams, theme, tenantTheme, isInitialized]);

  const togglePermissionsCheckComplete = (val?: boolean) => {
    if (val == null) {
      setIsPermissionsCheckComplete((prev) => !prev);
      return;
    }
    setIsPermissionsCheckComplete(val);
  };

  const updateOnboardingProgress = (payload: ProgressDataType) => {
    setOnBoardingProgress((prev) => ({ ...prev, ...payload }));
  };

  useEffect(() => {
    if (isTenantLoading) return;
    setIsInitialized(true);
  }, [isTenantLoading]);

  if (error || loading || isInTeams == null) return null;
  if (isTenantLoading || !tenantTheme) return null;

  return (
    <AppContext.Provider
      value={{
        isInTeams,
        teamsTheme: themeString,
        isPermissionsCheckComplete,
        togglePermissionsCheckComplete,
        onBoardingProgress,
        updateOnboardingProgress,
        isInitialized,
        tenantTheme,
        tenantData,
        appTheme,
        enableGetTenantData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => React.useContext(AppContext);

export { AppContext, AppContextProvider, useAppContext };
