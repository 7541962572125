import { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Flex, Header } from '@fluentui/react-northstar';
import { useGetOnboardingProgressMutation } from 'redux/services/onBoardingApi';
import { AppContext } from 'contexts/AppContext';
import { AuthContext } from 'contexts/AuthContext';
import { ProgressDataType } from 'types';

import Setup from './Setup';
import Toolbar from './Toolbar';

const Settings = (): React.ReactElement => {
  const { token } = useContext(AuthContext);
  const { updateOnboardingProgress } = useContext(AppContext);
  const [getProgress] = useGetOnboardingProgressMutation();

  const [currentCount, setCurrentCount] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
  const [withError, setWithError] = useState(false);

  const setupProps = {
    currentCount,
    setCurrentCount,
    currentStep,
    setCurrentStep,
    errorMessage,
    setErrorMessage,
    isCompleted,
    setIsCompleted,
    progressValue,
    setProgressValue,
    selectedCountry,
    setSelectedCountry,
    withError,
    setWithError,
  };

  const toolbarProps = {
    currentStep,
    isCompleted,
    selectedCountry,
    withError,
    setCurrentCount,
    setCurrentStep,
    setErrorMessage,
    setProgressValue,
    setWithError,
  };

  const setOnboardingProgress = useCallback(
    (result: ProgressDataType) => {
      if (updateOnboardingProgress) {
        updateOnboardingProgress(result);
      }
    },
    [updateOnboardingProgress],
  );

  useEffect(() => {
    getProgress({ token }).then((result) => {
      const response = result as { data: ProgressDataType };
      if (response.data) {
        setOnboardingProgress(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Toolbar {...toolbarProps} />
      <Flex>
        <Box
          style={{
            width: '20%',
            padding: '0 10px',
            borderRight: '1px solid #ebebeb',
          }}
        >
          <Header as="h3" content="Onboarding Setup" />
        </Box>
        <Setup {...setupProps} />
      </Flex>
    </Box>
  );
};

export default Settings;
