/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, FlexItem } from '@fluentui/react-northstar';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import {
  useGetOnboardingStatusMutation,
  useOnboardingPstnMutation,
  useOnboardingTenantDialPlanMutation,
  useOnboardingVoiceRouteMutation,
  useRetrySetupMutation,
} from 'redux/services/onBoardingApi';

import { useInterval } from 'hooks/useInterval';

import { useAppContext } from 'contexts/AppContext';
import { AuthContext } from 'contexts/AuthContext';

import { deepRefresh } from 'helpers/utils';
import { BatchUpdateResult, JobStatus, OnboardingStatus, OnboardingStep, RtkSuccessResponseType } from 'types';

import { INCREMENT_TIME, TIMEOUT_SECONDS } from '../../../constants/setup';

import ErrorBox from './ErrorBox';
import Step1 from './contents/Step1';
import Step2 from './contents/Step2';
import Step3 from './contents/Step3';
import Step4 from './contents/Step4';

import Stepper from 'components/common/Stepper';

type operatorConnectCount = {
  [key: string]: number;
};

type StepsType = {
  currentCount: number;
  currentStep: number;
  errorMessage: string;
  isCompleted: boolean;
  progressValue: number;
  selectedCountry: string[];
  withError: boolean;
  setCurrentCount: Dispatch<SetStateAction<number>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  setIsCompleted: Dispatch<SetStateAction<boolean>>;
  setProgressValue: Dispatch<SetStateAction<number>>;
  setSelectedCountry: Dispatch<SetStateAction<string[]>>;
  setWithError: Dispatch<SetStateAction<boolean>>;
};

const Setup = ({
  currentCount,
  currentStep,
  errorMessage,
  isCompleted,
  progressValue,
  selectedCountry,
  withError,
  setCurrentCount,
  setCurrentStep,
  setErrorMessage,
  setIsCompleted,
  setProgressValue,
  setSelectedCountry,
  setWithError,
}: StepsType): React.ReactElement => {
  const { token } = useContext(AuthContext);
  const { onBoardingProgress, appTheme, tenantData } = useAppContext();

  const [onboardPstn, { data: pstn, isLoading: isPstnLoading }] = useOnboardingPstnMutation();
  const [onboardVoiceRoute, { data: voiceRoute, isLoading: isVrLoading }] = useOnboardingVoiceRouteMutation();
  const [onboardDialPlan, { data: dialPlan, isLoading: isDpLoading }] = useOnboardingTenantDialPlanMutation();
  const [getStatus, { data: statusData }] = useGetOnboardingStatusMutation();
  const [retrySetupDomain] = useRetrySetupMutation();

  const [progressContent, setProgressContent] = useState('');
  const [resultCount, setResultCount] = useState(0);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [timerValue, setTimerValue] = useState(0);
  const [isProgressLoaded, setIsProgressLoaded] = useState(false);

  const isOnBoardingLoading = isPstnLoading || isVrLoading || isDpLoading;
  const isOperatorConnect = tenantData?.serviceType === 'OperatorConnect';

  const offsetCount = 2;

  const selectCountryCount = OnboardingStep.SELECT_COUNTRY;
  const setupPSTNCount = OnboardingStep.SETUP_PSTN - offsetCount;
  const addVoiceRouteCount = OnboardingStep.ADD_VOICE_ROUTES - offsetCount;
  const addDialPlansCount = OnboardingStep.ADD_DIAL_PLANS - offsetCount;

  const OC_STEP_COUNT: operatorConnectCount = {
    1: selectCountryCount,
    2: addDialPlansCount,
  };

  const currentOCStep = isOperatorConnect ? OC_STEP_COUNT[currentStep] : currentStep;

  const isOnStep1 = currentOCStep === selectCountryCount;
  const isOnStep2 = currentOCStep === setupPSTNCount;
  const isOnStep3 = currentOCStep === addVoiceRouteCount;
  const isOnStep4 = currentOCStep === addDialPlansCount;

  useEffect(() => {
    if (onBoardingProgress && !isProgressLoaded) {
      setIsProgressLoaded(true);
      const { id: jobId, status, onboardingStep, error, countryCodes } = onBoardingProgress;

      const step = currentStep || (onboardingStep > 1 ? onboardingStep - offsetCount : 1);
      const setUpStep = step || 1;

      setCurrentStep(setUpStep);

      if (!error) {
        setSelectedCountry(selectedCountry?.length ? selectedCountry : countryCodes);
        if (status === JobStatus.IN_PROGRESS) {
          setCurrentStep(setUpStep);
          getStatus({ jobId, token }).then((result) => {
            const res = result as { data: BatchUpdateResult<OnboardingStatus> };
            if (res.data) {
              setResultCount(res.data.results.length);
            }
          });
        }

        if (status === JobStatus.COMPLETED) {
          if ([setUpStep, currentOCStep].includes(addDialPlansCount)) {
            setIsCompleted(true);
            setCurrentStep(setUpStep);
          } else {
            setCurrentStep(setUpStep + 1);
          }
        }

        if (status === JobStatus.FAILED) {
          setCurrentStep(setUpStep);
          setWithError(true);
        }
      } else {
        setWithError(true);
        setErrorMessage(error);
        setCurrentStep(setUpStep);
      }
    }
  }, [getStatus, onBoardingProgress]);

  useEffect(() => {
    if (isCompleted && withError) return;

    switch (currentOCStep) {
      case setupPSTNCount:
        onboardPstn({ token, body: selectedCountry });
        break;
      case addVoiceRouteCount:
        onboardVoiceRoute({ token, body: selectedCountry });
        break;
      case addDialPlansCount:
        onboardDialPlan({ token, body: selectedCountry });
        break;
      case -1:
        retrySetupDomain({ token }).then(() => {
          setCurrentStep(1);
        });
        break;
      default:
    }
  }, [currentStep]);

  useEffect(() => {
    if (isOnBoardingLoading) {
      setProgressContent('loading...');
    }
  }, [isOnBoardingLoading]);

  useEffect(() => {
    if (timerValue === TIMEOUT_SECONDS) {
      setTimeLeft(30);
    }
  }, [timerValue]);

  useEffect(() => {
    if (timeLeft === 0) {
      deepRefresh();
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useInterval(() => {
    let jobId;

    switch (currentOCStep) {
      case setupPSTNCount:
        jobId = pstn?.id;
        break;
      case addVoiceRouteCount:
        jobId = voiceRoute?.id;
        break;
      case addDialPlansCount:
        jobId = dialPlan?.id;
        break;
      default:
    }

    setStepperContent(jobId);
    setTimerInfo();
  }, 3500);

  const isProgressCompleted = progressValue === 100;
  const isInProgress = !withError && !isProgressCompleted;

  const setStepperContent = (jobId: string) => {
    const { id, results, status } = statusData || {};

    if (jobId && isInProgress) {
      const totalCount = results?.length;

      if (totalCount) {
        const { name, onboardingRequestType } = results[currentCount].data;
        const mainContent = `${onboardingRequestType} - ${name}`;

        if (results[currentCount] && jobId === id) {
          let updatedCount = currentCount;

          if (results[currentCount].status === JobStatus.COMPLETED || status === JobStatus.COMPLETED) {
            updatedCount = currentCount + 1;
            setCurrentCount(updatedCount);
            setProgressValue(Math.round((updatedCount / totalCount) * 100));
          }

          setProgressContent(`${mainContent}... (${updatedCount} of ${totalCount} completed)`);

          if (results[currentCount].status === JobStatus.FAILED || status === JobStatus.FAILED) {
            setWithError(true);
            setErrorMessage(results[currentCount].errors?.[0]?.message);
          }
        }
      }

      getStatus({ jobId, token }).then((result) => {
        const data = result as RtkSuccessResponseType<BatchUpdateResult<OnboardingStatus>>;
        if (data) {
          setResultCount(data.data.results.length);
        }
      });
    }

    if (isProgressCompleted) {
      if (currentOCStep < addDialPlansCount && currentOCStep > OnboardingStep.SELECT_COUNTRY) {
        resetStepperContent();
      }

      if (currentOCStep === addDialPlansCount && results) {
        if (results[currentCount].status === JobStatus.FAILED || status === JobStatus.FAILED) {
          setWithError(true);
          setErrorMessage(results[currentCount].errors?.[0]?.message);
        } else {
          setIsCompleted(true);
        }
      }
    }
  };

  const setTimerInfo = () => {
    if (isInProgress) {
      const stepProgressValue = Math.round(resultCount > 0 ? ((currentCount + 1) / resultCount) * 100 : 0);
      const increasedProgressValue = progressValue + INCREMENT_TIME;

      let currentProgressValue = stepProgressValue;

      if (increasedProgressValue < stepProgressValue) currentProgressValue = increasedProgressValue;

      if (progressValue === currentProgressValue) setTimerValue(timerValue + INCREMENT_TIME);
      else setTimerValue(0);
    } else {
      if (!isProgressCompleted) setTimerValue(timerValue + INCREMENT_TIME);
    }
  };

  const resetState = () => {
    setProgressValue(0);
    setCurrentCount(0);
  };

  const resetStepperContent = () => {
    setCurrentStep(currentStep + 1);
    resetState();
  };

  const errorBoxProps = {
    errorMessage,
    timeLeft,
    withError,
  };

  const commonStepProps = {
    progressContent,
    progressValue,
    withError,
  };

  const step4Props = {
    ...commonStepProps,
    isCompleted: isOnStep4 && isProgressCompleted,
  };

  const ONBOARDING_STEPS = ['Select Country', 'PSTN Setup', 'Add Voice Routes', 'Add Dial Plans'];
  const OC_ONBOARDING_STEPS = ['Select Country', 'Add Dial Plans'];

  return (
    <>
      <Box className="stepper-container-horizontal" style={{ width: '100%' }}>
        <Flex gap="gap.large" style={{ marginBottom: 20 }}>
          <Stepper
            direction="vertical"
            currentStepNumber={currentStep - 1}
            steps={isOperatorConnect ? OC_ONBOARDING_STEPS : ONBOARDING_STEPS}
            stepColor={appTheme?.siteVariables?.colors?.brand?.['base']}
          />
          <FlexItem>
            <Box style={{ width: '100%' }}>
              <ErrorBox {...errorBoxProps} />
              {isOnStep1 && <Step1 setSelectedCountry={setSelectedCountry} />}
              {isOnStep2 && <Step2 {...commonStepProps} />}
              {isOnStep3 && <Step3 {...commonStepProps} />}
              {isOnStep4 && <Step4 {...step4Props} />}
            </Box>
          </FlexItem>
        </Flex>
      </Box>
    </>
  );
};

export default Setup;
